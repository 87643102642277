import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

type DisclaimerBlockProps = {
    keyPrefix: string;
    translationDisclaimerKeys: string[];
};

const DisclaimerBlock = ({ keyPrefix, translationDisclaimerKeys }: DisclaimerBlockProps) => {
    const { t } = useModifiedTranslation();
    const { data: contactData } = useGetContactInfo();
    
    const [firstDisclaimerKey, ...restDisclaimerKeys] = translationDisclaimerKeys;

    const formatedText = (htmlString: string) => {

        if (contactData?.tags?.includes('Company-LT')) {
            const lvHostName = "citadele.lv";
            const ltHostname = "citadele.lt";
    
            const regex = new RegExp(lvHostName, 'g');
    
            const updatedHtmlString = htmlString.replace(regex, ltHostname);
            return { __html: updatedHtmlString };
        }

        return { __html: htmlString };
    }

    return (
        <div className="text-xs text-left text-gray-600 max-w-[375px]">
            <p> {t(`${keyPrefix}.${firstDisclaimerKey}`)}</p>
            <br />
            <ul className="pl-5 list-disc">
                {restDisclaimerKeys.map(key => (
                    <li key={key} dangerouslySetInnerHTML={formatedText(t(`${keyPrefix}.${key}`))}></li>
                ))}
            </ul>
        </div>
    );
};
export default DisclaimerBlock;
